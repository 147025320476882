import { ItemBar, ItemBarLeft } from "components/ItemBar";
import { LoadingSpinner } from "components/LoadingSpinner";
import { isValidUUID } from "helpers/uuid";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { debounce } from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllPatientsQuery } from "store/queries/patient";
import styled from "styled-components";

const StyledPageDataTable = styled(DataTable)`
  .p-sortable-column .p-sortable-column-icon {
    display: none !important;
  }
  .p-datatable-tbody > tr > td.p-highlight {
    background-color: unset;
    color: unset;
  }
  .p-sortable-column .p-column-title {
    display: flex;
    gap: ${sizer(1)};
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px !important;
  width: fit-content !important;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const StyledInput = styled(InputText)`
  padding-right: 35px;
`;

export function PatientsTable() {
  const query = useQuery();
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);
  const [rows] = useState(25);
  const [filters, setFilters] = useState({
    firstName: query.get("firstName") || "",
    lastName: query.get("lastName") || "",
    phoneNumber: query.get("phoneNumber") || "",
    accountNumber: query.get("accountNumber") || "",
    orderId: query.get("orderId") || "",
    accountName: query.get("accountName") || "",
  });
  const [debouncedFilters, setDebouncedFilters] = useState(filters);
  const [uuidNotValid, setUuidNotValid] = useState(false);
  useEffect(() => {
    const handler = debounce(() => setDebouncedFilters(filters), 1000);
    handler();
    return () => {
      handler.cancel();
    };
  }, [filters]);
  const { data, isLoading, isFetching } = useGetAllPatientsQuery(
    {
      page: first,
      pageSize: rows,
      ...debouncedFilters,
    },
    {
      skip: debouncedFilters.orderId?.length
        ? !isValidUUID(debouncedFilters.orderId)
        : false,
    }
  );

  useEffect(() => {
    const orderIdToSearch = query.get("orderId");
    if (orderIdToSearch?.length && !isValidUUID(orderIdToSearch)) {
      setUuidNotValid(true);
      return;
    }
    setUuidNotValid(false);
  }, []);

  const parsedPatients = useMemo(() => {
    if (!data) return [];
    const { data: patients } = data;
    const parsed = patients.map((patient) => {
      return {
        patientId: patient.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        phoneNumber: patient.phoneNumber,
        accountNumber: patient.orders[0].accountNumber,
        orderId: patient.orders[0].id,
        accountName: patient.account.name,
        accountId: patient.account.id,
      };
    });
    return parsed;
  }, [data]);
  const debouncedFilterChange = debounce((updateFilter) => updateFilter(), 500);

  function handleOnChangeFilter({
    newValue,
    field,
  }: {
    newValue: string;
    field:
      | "firstName"
      | "lastName"
      | "phoneNumber"
      | "accountNumber"
      | "orderId"
      | "accountName";
  }) {
    setFilters((prevData) => ({ ...prevData, [field]: newValue }));
    const updateFilter = () => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(field, newValue);
      navigate(`?${searchParams.toString()}`, { replace: true });
    };
    debouncedFilterChange(updateFilter);
  }

  const onPageChange = (event: PaginatorPageState) => {
    setFirst(event.first);
  };

  const handlePasteOrderId = (
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    const pastedText = event.clipboardData.getData("text").trim();
    const isValidUuid = isValidUUID(pastedText);
    handleOnChangeFilter({ newValue: pastedText, field: "orderId" });
    if (isValidUuid) {
      setUuidNotValid(false);
    } else {
      setUuidNotValid(true);
    }
  };

  function orderIdColumn(row: any) {
    return (
      <Button
        className="p-button-link"
        data-testid="order_action_OrderTable"
        onClick={() => navigate(`/orders/${row.orderId}`)}
      >
        <p className="small-text">{row.orderId}</p>
      </Button>
    );
  }

  function accountNameColumn(row: any) {
    return (
      <Button
        className="p-button-link"
        data-testid="order_action_OrderTable"
        onClick={() => navigate(`/hospitals/view/${row.accountId}`)}
      >
        <p className="small-text">{row.accountName}</p>
      </Button>
    );
  }

  if (isLoading || isFetching) return <LoadingSpinner />;

  return (
    <div>
      <ItemBar>
        <ItemBarLeft style={{ minHeight: "67px", alignItems: "start" }}>
          <InputText
            data-testid="name_multiselect_PatientsTable"
            id="patientSearchByFirstName"
            value={filters.firstName}
            className="align-self-start"
            placeholder="Search by first name"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "firstName",
              })
            }
          />
          <InputText
            data-testid="name_multiselect_PatientsTable"
            id="patientSearchByLastName"
            className="align-self-start"
            value={filters.lastName}
            placeholder="Search by last name"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "lastName",
              })
            }
          />
          <InputText
            data-testid="name_multiselect_PatientsTable"
            style={{ minWidth: "230px" }}
            className="align-self-start"
            id="patientSearchByPhoneNumber"
            value={filters.phoneNumber}
            placeholder="Search by phone number"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "phoneNumber",
              })
            }
          />
          <InputText
            data-testid="name_multiselect_PatientsTable"
            id="patientSearchByAccountName"
            className="align-self-start"
            style={{ minWidth: "230px" }}
            value={filters.accountName}
            placeholder="Search by account name"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "accountName",
              })
            }
          />
          <InputText
            data-testid="name_multiselect_PatientsTable"
            style={{ minWidth: "250px" }}
            className="align-self-start"
            id="patientSearchByAccountNumber"
            value={filters.accountNumber}
            placeholder="Search by account number"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "accountNumber",
              })
            }
          />
          <div className="flex flex-column align-self-start">
            <div className="relative">
              <StyledInput
                data-testid="name_multiselect_PatientsTable"
                id="patientSearchByOrderId"
                value={filters.orderId}
                placeholder="Paste Order ID here"
                readOnly
                onPaste={handlePasteOrderId}
              />
              {filters.orderId && (
                <StyledButton
                  onClick={() => {
                    setUuidNotValid(false);
                    handleOnChangeFilter({ newValue: "", field: "orderId" });
                  }}
                  icon="pi pi-times-circle"
                  className="p-button-text"
                />
              )}
            </div>
            {uuidNotValid && (
              <p className="p-error text-sm mt-1">
                Please, insert correct order id
              </p>
            )}
          </div>
        </ItemBarLeft>
      </ItemBar>
      <StyledPageDataTable
        emptyMessage="No patients found."
        stripedRows
        className="p-fluid"
        value={parsedPatients}
        responsiveLayout="scroll"
      >
        <Column
          field="firstName"
          header="Patient First Name"
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="lastName"
          header="Patient Last Name"
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="phoneNumber"
          header="Phone Number"
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="accountName"
          header="Hospital Name"
          headerStyle={{ width: "15%" }}
          body={(row) => accountNameColumn(row)}
        />
        <Column
          field="accountNumber"
          header="Account Number"
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="orderId"
          header="Order Id"
          headerStyle={{ width: "25%" }}
          body={(row) => orderIdColumn(row)}
        />
      </StyledPageDataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={data?.total}
        onPageChange={onPageChange}
      />
    </div>
  );
}
