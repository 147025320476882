import { Clock } from "assets/icons/Clock";
import { Cube } from "assets/icons/Cube";
import { Payment } from "assets/icons/Payment";
import { Trend } from "assets/icons/Trend";
import { Users } from "assets/icons/Users";
import { orderStatusConstants } from "constants/order";
import dayjs from "dayjs";
import { centsToDollars } from "helpers/price";
import { Label } from "layout/typography/Label";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { useEffect, useMemo, useState } from "react";
import { IOrder } from "types/Order/Order";

import { HorizontalLine } from "../styled";
import { Ticket } from "./components/Ticket";
import { tooltipTexts } from "./helpers/tooltipTexts";

export function TicketCharts({ orders }: { orders: IOrder[] }) {
  const startOfMonth = dayjs().startOf("month").toDate();
  const today = dayjs().endOf("day").toDate();

  const [allOrders, setAllOrders] = useState<IOrder[]>(
    orders.filter(
      (order) =>
        !(
          order.status === orderStatusConstants.CANCELED &&
          !order.payments.length
        )
    )
  );
  const [date, setDate] = useState<Date[]>([startOfMonth, today]);

  useEffect(() => {
    const filteredOrdersByDate = allOrders.filter((order) => {
      const dateCreated = dayjs.utc(order.createdAt).startOf("day").hour(18);
      return dateCreated.isBetween(date[0], date[1], "day", "[]");
    });
    setAllOrders(filteredOrdersByDate);
  }, []);

  const paid = useMemo(() => {
    const result = allOrders.filter(
      (order) =>
        order.status === orderStatusConstants.PAID ||
        order.status === orderStatusConstants.PARTIALLY_PAID
    ).length;
    return { result };
  }, [allOrders]);

  const conversionRate = useMemo(() => {
    const paid = allOrders.filter(
      (order) =>
        order.status === orderStatusConstants.PAID ||
        order.status === orderStatusConstants.PARTIALLY_PAID
    ).length;

    const ordersWithoutSentAndDraft = allOrders.filter(
      (order) =>
        order.status !== orderStatusConstants.SENT_TO_PATIENT &&
        order.status !== orderStatusConstants.DRAFT &&
        order.status !== orderStatusConstants.NO_BALANCE_DUE
    ).length;
    const result = (paid / ordersWithoutSentAndDraft) * 100;
    return { result };
  }, [allOrders]);

  const averageDaysToCollect = useMemo(() => {
    const allPaidOrders = allOrders.filter(
      (order) =>
        order.status === orderStatusConstants.PAID ||
        order.status === orderStatusConstants.PARTIALLY_PAID
    );
    const totalDaysToCollect = allPaidOrders.reduce((acc, order) => {
      const dateCreated = dayjs.utc(order.createdAt);
      const earliestPaymentDate = order.payments.reduce((earliest, payment) => {
        const paymentDate = dayjs.utc(payment.createdAt);
        return paymentDate.isBefore(earliest) ? paymentDate : earliest;
      }, dayjs.utc(order.payments[0]?.createdAt));
      const daysToCollect = earliestPaymentDate.diff(dateCreated, "day");
      return acc + daysToCollect;
    }, 0);
    const result = totalDaysToCollect / allPaidOrders.length;
    return { result: Number(result.toFixed(1)) };
  }, [allOrders]);

  const totalRevenue = useMemo(() => {
    const allPaidOrders = allOrders.filter(
      (order) =>
        order.status === orderStatusConstants.PAID ||
        order.status === orderStatusConstants.PARTIALLY_PAID
    );
    const result = allPaidOrders.reduce((acc, order) => {
      const paidAmount = order.payments.reduce(
        (sum, aPayment) => sum + (aPayment?.amountInCents || 0),
        0
      );
      return acc + (paidAmount ?? 0);
    }, 0);
    const resultToTwoDecimals = Number(result.toFixed(2));
    return resultToTwoDecimals;
  }, [allOrders]);

  function handleOnChangeFilter({
    newValue,
  }: {
    newValue: CalendarValueType | string;
  }) {
    setDate(newValue as Date[]);
    const [startDate, endDate] = newValue as Date[];
    const start = dayjs.utc(startDate);
    const end = dayjs(endDate).endOf("day").toDate();

    const filteredFromCancelled = orders.filter(
      (order) =>
        !(
          order.status === orderStatusConstants.CANCELED &&
          !order.payments.length
        )
    );
    if (!startDate && !endDate) {
      setAllOrders(filteredFromCancelled);
    }
    if (startDate && endDate) {
      setAllOrders(
        filteredFromCancelled.filter((order) => {
          const dateCreated = dayjs
            .utc(order.createdAt)
            .startOf("day")
            .hour(18);
          return dateCreated.isBetween(start, end, "day", "[]");
        })
      );
    } else if (startDate && !endDate) {
      setAllOrders(
        filteredFromCancelled.filter((order) => {
          const dateCreated = dayjs
            .utc(order.createdAt)
            .startOf("day")
            .hour(18);
          return dateCreated.isAfter(start);
        })
      );
    }
  }
  return (
    <div className="flex flex-column gap-2 w-full">
      <div
        className="flex flex-column gap-2 w-fit"
        style={{ minWidth: "250px" }}
      >
        <Label htmlFor="from">Order Created Date</Label>
        <Calendar
          data-testid="range_calendar"
          selectionMode="range"
          placeholder="Order Created Date"
          value={date}
          showButtonBar
          className="w-fit"
          onChange={(e) => {
            handleOnChangeFilter({
              newValue: e.target.value || [],
            });
          }}
        />
      </div>
      <div className="w-full my-3 flex gap-4 flex-wrap">
        <Ticket
          icon={<Users width={24} height={24} />}
          color="#F9F9FB"
          title="Estimates Created"
          tooltipText={tooltipTexts.estimatesCreated}
          result={allOrders.length}
        />
        <Ticket
          icon={<Cube width={24} height={24} />}
          color="#E1E2EC"
          title="Paid"
          tooltipText={tooltipTexts.paid}
          result={paid.result}
        />
        <Ticket
          icon={<Trend width={24} height={24} />}
          color="#E0F4F1"
          title="Conversion Rate"
          tooltipText={tooltipTexts.conversionRate}
          percentage
          result={conversionRate.result}
        />
        <Ticket
          icon={<Payment width={24} height={24} />}
          color="#F9F9FB"
          title="Average Days to Collect"
          tooltipText={tooltipTexts.averageDaysToCollect}
          result={averageDaysToCollect.result}
        />
        <Ticket
          icon={<Clock width={24} height={24} />}
          color="#E1E2EC"
          title="Total Revenue"
          tooltipText={tooltipTexts.totalRevenue}
          currency
          result={centsToDollars(totalRevenue)}
        />
      </div>
      <HorizontalLine />
    </div>
  );
}
