import { useMemo } from "react";
import { useGetAccountByIdQuery } from "store/queries/account";

import { useQuery } from "./useQuery";

type AccountFeeType =
  | "ClearTransactionFee"
  | "CTFForPR"
  | "PRDiscount"
  | "ClearHealthFeeForGFE"
  | "ClearHealthDiscountForGFE";

export function useGetAccountDiscountAndFee(discountType: AccountFeeType) {
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;

  const { data, isLoading } = useGetAccountByIdQuery(accountId || "");

  const accountDiscount = useMemo(() => {
    if (!data || !data.data || !data.data.accountFees) {
      return 0;
    }

    const { accountFees } = data.data;

    if (!accountFees.length) {
      return 0;
    }

    return (
      accountFees.find((accountFee) => accountFee.type === discountType)
        ?.valueInPercentage || 0
    );
  }, [data]);

  return { accountDiscount, isLoading };
}
