import { pdf } from "@react-pdf/renderer";
import { Chip } from "components/Chip";
import {
  orderStatusChipVariantsMapping,
  orderStatusConstants,
  orderStatusMapping,
  orderTypeMapping,
  orderTypeConstants,
} from "constants/order";
import { orderHsitoryTypeConstants } from "constants/orderHistory";
import { events } from "constants/tagManager";
import { UserRole } from "constants/userRole";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { parseDate } from "helpers/date";
import {
  sumOfFacilityProcedures,
  sumOfProvidersProcedures,
} from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import { useOrder } from "hooks/useOrder";
import usePrice from "hooks/usePrice";
import { sizer } from "layout/styles/styled/sizer";
import { Steps } from "pages/OrderOverview/utils/constants";
import { Steps as OrderUpdateSteps } from "pages/OrderUpdate/utils/constants";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import {
  useCancelSingleOrderMutation,
  useCreatePaymentMutation,
  useUpdateOrderMutation,
} from "store/queries/order";
import { useCreateOrderHistoryMutation } from "store/queries/orderHistory";
import {
  useMarkOrderBlockedTransfersMutation,
  useMarkPaymentReadyForTransfersMutation,
} from "store/queries/payment";
import { onPublicTokenChange } from "store/slices/auth";
import {
  onChangeViewOrderViewReceipt,
  onOrderOverviewStepChange,
  onOrderUpdateStepChange,
} from "store/slices/order";
import { userSelector } from "store/slices/user";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";
import { OrderStatus } from "types/Order/OrderStatus";
import { IOrderHistory } from "types/OrderHistory";

import { CancelOrderDialog } from "./components/CancelOrderDialog/CancelOrderDialog";
import { ClearOrderDocument } from "./components/ClearOrderDocument";
import { ClearOrderProDownloadButton } from "./components/Download";
import { MarkAsPaidCashDialog } from "./components/MarkAsPaidCashDialog/MarkAsPaidCashDialog";
import { MoneyTransferDialog } from "./components/MoneyTransferDialog";
import { NoteToPdfDialog } from "./NoteToPdfDialog/NoteToPdfDialog";

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

const HeadingContainer = styled.div`
  gap: ${sizer(4)};
  margin-bottom: ${sizer(2)};
`;

const StyledSubtitle = styled.h2`
  color: var(--bluegray-500);
  font-size: var(--fontsize-contents);
  font-weight: var(--font-weight-medium);

  line-height: ${sizer(5)};
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${sizer(2)};
  justify-content: space-between;
`;

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

type PaymentType = "Financing" | "Cash";

export function Header({
  order,
  onUpdateOrder,
  orderHistories,
  handleRedoOrder,
}: {
  order: IOrder;
  onUpdateOrder: () => void;
  orderHistories: IOrderHistory[];
  handleRedoOrder?: () => void;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageViewEvents } = useAnalytics();
  const { scope } = useSelector(userSelector);
  const {
    isGFE,
    mainFacility,
    isStatusSentToPatient,
    isBariatricsNotReadyForTransfer,
    isStatusExpired,
    payment,
    isDefaultOrderType,
    isStatusPaid,
  } = useOrder(order);

  const { getClearPriceByOrderType, getInsuranceAmounts, insuranceAmounts } =
    usePrice();

  const { current: toastElement } = useContext(ToastContext);

  const [login] = useLoginWithOrderIdAndDOBMutation();
  const [createPayment] = useCreatePaymentMutation();
  const [cancelSingleOrder] = useCancelSingleOrderMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [markPaymentReadyForTransfers] =
    useMarkPaymentReadyForTransfersMutation();
  const [markOrderBlockedTransfers] = useMarkOrderBlockedTransfersMutation();
  const { userInfo } = useUserWithAccount();

  const [showDialog, setShowDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancelNotes, setCancelNotes] = useState("");
  const [transfers, setTransfers] = useState([
    {
      selectedPartyToTransferFrom: null,
      selectedPartyToTransferTo: null,
      selectedAmountToTransfer: 0,
    },
  ]);
  const [showMoneyTransferDialog, setShowMoneyTransferDialog] = useState(false);
  const [providerToCharge, setProviderToCharge] = useState<string>();
  const showPartialPayment = useMemo(() => {
    // show only for those orders that are paid and do not have any cash payment
    return (
      [orderStatusConstants.PAID, orderStatusConstants.PARTIALLY_PAID].includes(
        order.status
      ) && !order.payments.some((payment) => payment.type === "Cash")
    );
  }, [order]);

  const showReceiptButton = useMemo(() => {
    const orderPayments = order.payments;
    return (
      [
        orderStatusConstants.PAID,
        orderStatusConstants.PARTIALLY_PAID,
        orderStatusConstants.CANCELED,
        orderStatusConstants.REFUNDED,
      ].includes(order.status) && !!orderPayments.length
    );
  }, [order]);

  // only show the pay remaining amount button for partially paid orders
  const showPayRemainingAmountButton = useMemo(() => {
    return [orderStatusConstants.PARTIALLY_PAID].includes(order.status);
  }, [order]);

  useEffect(() => {
    getInsuranceAmounts(order.gfeQuote?.pricingBreakdown);
  }, [order.gfeQuote?.pricingBreakdown]);

  const { clearPrice, percentageOff } = getClearPriceByOrderType({
    order,
  });

  const gfeNoDiscountPdf = useMemo(() => {
    const gfeCustoms = order.account.customizes.find(
      (c) => c.orderType === orderTypeConstants.GFE
    );
    return gfeCustoms?.downloadPdfContent?.noDiscountPdf;
  }, [order]);

  const gfeAddNoteToPdf = useMemo(() => {
    const gfeCustoms = order.account.customizes.find(
      (c) => c.orderType === orderTypeConstants.GFE
    );
    return gfeCustoms?.downloadPdfContent?.addNoteToPdf;
  }, [order]);

  const showDownloadWithNote = useMemo(() => {
    if (isGFE && gfeAddNoteToPdf) return true;
    return false;
  }, [isGFE, gfeNoDiscountPdf, gfeAddNoteToPdf]);

  const isVersion3Pdf = useMemo(() => {
    const { orderType } = order;
    const customs = order.account.customizes.find(
      (c) => c.orderType === orderType
    );
    return !!customs?.downloadPdfContent?.version3Pdf;
  }, [order]);

  const gfeDisclaimer = useMemo(() => {
    const gfeCustoms = order.account.customizes.find(
      (c) => c.orderType === orderTypeConstants.GFE
    );
    const disclaimerEnglish = {
      title: gfeCustoms?.overviewPage.disclaimerTitleEnglish,
      description: gfeCustoms?.overviewPage.disclaimerDescriptionEnglish,
    };
    const disclaimerSpanish = {
      title: gfeCustoms?.overviewPage.disclaimerTitleSpanish,
      description: gfeCustoms?.overviewPage.disclaimerDescriptionSpanish,
    };
    return {
      disclaimerEnglish,
      disclaimerSpanish,
    };
  }, [order]);

  const getAmountFinancingOrCash = (paymentType: PaymentType) => {
    let amountInCents: number;

    if (paymentType === "Financing") {
      amountInCents = order.amountInCents;
    } else {
      amountInCents = clearPrice;
    }

    return amountInCents;
  };

  const markViaFinancingOrCash = (paymentType: PaymentType, notes?: string) => {
    const amountInCents: number = getAmountFinancingOrCash(paymentType);

    login({
      orderId: order.externalId || "",
      dateOfBirth: new Date(order.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));

        createPayment({
          orderId: order.id,
          amountInCents,
          paymentType,
          providerId: providerToCharge,
          userId: userInfo?.id,
          note: notes?.trim()?.length ? notes.trim() : undefined,
        })
          .unwrap()
          .then(async () => {
            onUpdateOrder();
            pageViewEvents(
              {
                accountName: order.account.name,
                orderType: order.orderType,
                communicationMethod: order.patient.preferredContactMethod,
              },
              events.CLICK_FINANCING
            );

            toastElement?.show({
              summary: "Success!",
              severity: "success",
              detail: "Your order has been updated.",
            });
          })
          .catch(() => {
            toastElement?.show({
              severity: "error",
              detail: "Try again later.",
              summary: "Something went wrong.",
            });
          });
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };

  const acceptPayment = (step: Steps) => {
    login({
      orderId: order.externalId || "",
      dateOfBirth: new Date(order.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(
          onOrderOverviewStepChange({
            step,
          })
        );
        pageViewEvents(
          {
            accountName: order.account.name,
            orderType: order.orderType,
            communicationMethod: order.patient.preferredContactMethod,
          },
          events.CLICK_ACCEPT_PAYMENT
        );
        localStorage.setItem("clear_userType", "internal");
        localStorage.setItem("clear_dob", `${order.patient.dateOfBirth}`);
        window.open(`/order/${order.externalId}?internalUser=true`, "_blank");
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  const changeCardDetails = (step: OrderUpdateSteps) => {
    login({
      orderId: order.externalId || "",
      dateOfBirth: new Date(order.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(
          onOrderUpdateStepChange({
            step,
          })
        );
        pageViewEvents(
          {
            accountName: order.account.name,
            orderType: order.orderType,
            communicationMethod: order.patient.preferredContactMethod,
          },
          events.CLICK_ACCEPT_PAYMENT
        );
        localStorage.setItem("clear_userType", "internal");
        localStorage.setItem("clear_dob", `${order.patient.dateOfBirth}`);
        window.open(
          `/order/update/${order.externalId}?internalUser=true`,
          "_blank"
        );
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  const cancelOrder = () => {
    cancelSingleOrder({
      orderId: order.id,
      note: cancelNotes.trim()?.length ? cancelNotes.trim() : undefined,
    })
      .unwrap()
      .then(async () => {
        toastElement?.show({
          severity: "success",
          summary: "Order canceled",
          detail: "Order canceled successfully.",
        });
        onUpdateOrder();
      })
      .catch((error) =>
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error.message}`,
        })
      );
    setShowCancelDialog(false);
  };

  const changeOrderStatus = (newStatus: OrderStatus) => {
    updateOrder({
      ...order,
      id: order.id,
      status: newStatus,
    })
      .then(() => {
        onUpdateOrder();
        toastElement?.show({
          severity: "success",
          summary: "Order status changed",
          detail: "Order status changed successfully",
        });
      })
      .catch((error) => {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error.message}`,
        });
      });
  };

  const handleOnClickToMarkCashPaid = () => {
    setShowDialog(true);
  };

  const handleOnClickToMarkReadyForTransfer = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to mark the payment on this order as ready for Stripe transfers?`,
      accept: () => {
        if (!payment?.id) return;
        markPaymentReadyForTransfers(payment.id)
          .unwrap()
          .then(() => {
            onUpdateOrder();
            toastElement?.show({
              severity: "success",
              summary: "Ready for transfers",
              detail: "Stripe transfers will be initiated shortly",
            });
          })
          .catch((error) =>
            toastElement?.show({
              severity: "error",
              summary: "Error",
              detail: `Message: ${error}`,
            })
          );
      },
    });
  };

  const handleOnClickToMarkBlockedOrderForTransfer = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to block transfer(s) for this order?`,
      accept: () => {
        if (!order?.id) return;
        markOrderBlockedTransfers(order.id)
          .unwrap()
          .then(() => {
            onUpdateOrder();
            toastElement?.show({
              severity: "success",
              summary: "Blocked for transfers",
              detail: "Transfers are blocked for the order",
            });
          })
          .catch((error) =>
            toastElement?.show({
              severity: "error",
              summary: "Error",
              detail: `Message: ${error}`,
            })
          );
      },
    });
  };

  const handleOnClickToCancelOrder = () => {
    setShowCancelDialog(true);
  };

  const handleOnClickPartialRefund = () => {
    setShowMoneyTransferDialog(true);
  };

  const handleOnClickToChangeOrderStatus = (value: OrderStatus) => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to change this order status?`,
      accept: () => changeOrderStatus(value),
    });
  };

  const handleGoToOrdersPage = () => {
    navigate(`/hospitals/view/${order.account.id}${location.search}`);
  };

  const [showNoteToPdfDialog, setShowNoteToPdfDialog] = useState(false);
  const [noteForPdfDialog, setNoteForPdfDialog] = useState("");
  const [hoursEstimated, setHoursEstimated] = useState<number | null>(null);
  const [createOrderHistory] = useCreateOrderHistoryMutation();

  const sortedOrderHistories = useMemo(() => {
    return [...orderHistories].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [orderHistories]);
  const mostRecentNote = useMemo(() => {
    // Ensure the array is sorted in descending order by createdAt
    const sortedNotes = sortedOrderHistories.filter(
      (item) => item.type === orderHsitoryTypeConstants.NoteToPdf
    );

    // Access the first item of the sorted array
    return sortedNotes.length ? sortedNotes[0].note || "" : "";
  }, [sortedOrderHistories]);

  const handleOnClickToAddNoteForPdf = () => {
    setNoteForPdfDialog(mostRecentNote);
    setShowNoteToPdfDialog(true);
  };

  const breakdown = useMemo(() => {
    if (order.status === "Paid") {
      return order?.payments[0]?.amountBreakdown?.bundledProviderAmounts;
    }
    return order?.amountBreakdown?.bundledProviderAmounts;
  }, [order]);

  // Define the data object for PDF download outside the function
  const dataForPdfDownload = {
    facilityLogo: order.account.accountLogoUrl || "",
    name: `${order.patient.firstName} ${order.patient.lastName}`,
    fullAddress: `${mainFacility.streetAddress}, ${mainFacility.city}, ${mainFacility.state}`,
    phoneNumber: mainFacility.publicContactPhone,
    dateOfBirth: parseDate(order.patient.dateOfBirth),
    payerName: (order.serviceDetails as any)?.payerName,
    gfeNoDiscountPdf,
    gfeDisclaimer,
    serviceDate: order.dateOfService,
    serviceDetails: order.serviceDetails,
    facilityName: mainFacility.name,
    gfeQuote: order.gfeQuote,
    clearDiscount: percentageOff,
    clearPriceToPay: clearPrice,
    facilityFee: sumOfFacilityProcedures(order.serviceDetails.procedures),
    accountNumber: order.accountNumber,
    providerFee: sumOfProvidersProcedures(order.serviceDetails.procedures),
    isGFE,
    insuranceAmounts,
    isVersion3Pdf,
    hoursEstimated,
    fullProviderBreakdown: breakdown,
  };

  const handleSaveNoteForPdf = async (note: string) => {
    try {
      // Generate and download the PDF after successful order history creation
      const blob = await pdf(
        <ClearOrderDocument {...dataForPdfDownload} note={note} />
      ).toBlob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `clear_order__${new Date().getTime()}.pdf`;
      document.body.appendChild(link);
      // Check if the note has changed
      if (note.trim() !== mostRecentNote.trim()) {
        // Create order history only if the note has changed
        await createOrderHistory({
          orderId: order.id,
          type: orderHsitoryTypeConstants.NoteToPdf,
          note: note.trim(),
          userId: userInfo?.id,
        }).unwrap();

        onUpdateOrder();
        toastElement?.show({
          severity: "success",
          summary: "Success!",
          detail: "Your note has been added successfully.",
        });

        link.click();
        document.body.removeChild(link);
      } else {
        // If the note hasn't changed, download the PDF immediately
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to save note",
      });
    }
  };

  return (
    <Container>
      <MarkAsPaidCashDialog
        paymentType="Cash"
        markViaFinancingOrCash={markViaFinancingOrCash}
        order={order}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        providerToCharge={providerToCharge}
        setProviderToCharge={setProviderToCharge}
      />
      <MoneyTransferDialog
        showDialog={showMoneyTransferDialog}
        order={order}
        setShowDialog={setShowMoneyTransferDialog}
        transfers={transfers}
        setTransfers={setTransfers}
        onUpdateOrder={onUpdateOrder}
      />
      <CancelOrderDialog
        showCancelDialog={showCancelDialog}
        setShowCancelDialog={setShowCancelDialog}
        cancelNotes={cancelNotes}
        setCancelNotes={setCancelNotes}
        onCancelOrder={cancelOrder}
      />
      <div>
        <StyledButton
          data-testid="orders-dashboard_OrderHeader"
          icon="pi pi-arrow-left"
          onClick={handleGoToOrdersPage}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="small-text">Orders Dashboard</p>
        </StyledButton>
        <StyledH2>{order?.account.name}</StyledH2>

        <HeadingContainer className="flex align-items-center">
          <h1>Order Details</h1>
          {isStatusExpired ? (
            <Dropdown
              data-testid="change-order-status-dropdown_OrderHeader"
              className="p-dropdown-chip"
              value={orderStatusMapping[order?.status]}
              onChange={(e) => {
                handleOnClickToChangeOrderStatus(e.value.code as OrderStatus);
              }}
              options={[
                {
                  name: "Change status to Sent",
                  code: orderStatusConstants.SENT_TO_PATIENT,
                },
              ]}
              optionLabel="name"
              placeholder="Expired"
            />
          ) : (
            <Chip variant={orderStatusChipVariantsMapping[order?.status]}>
              {orderStatusMapping[order?.status]}
            </Chip>
          )}
        </HeadingContainer>

        <StyledSubtitle>
          {`${orderTypeMapping[order?.orderType]} • Created on ${parseDate(
            order?.createdAt || ""
          )}`}
        </StyledSubtitle>
      </div>

      <div className="flex gap-3 flex-wrap justify-end">
        {showPayRemainingAmountButton && (
          <StyledButton
            data-testid="accept-remaining-amount-payment_OrderHeader"
            icon="pi pi-check-circle"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => navigate(`/order/pay/${order.externalId}`)}
          >
            <p className="small-text">Pay Remaining Amount</p>
          </StyledButton>
        )}
        {showReceiptButton && (
          <StyledButton
            data-testid="payment-receipt_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => dispatch(onChangeViewOrderViewReceipt(true))}
          >
            <p className="small-text">Payment receipt</p>
          </StyledButton>
        )}
        {isStatusSentToPatient && (
          <>
            {scope === UserRole.SUPER_ADMIN && (
              <StyledButton
                data-testid="mark-paid-cash_OrderHeader"
                icon="pi pi-check-circle"
                className="p-button-text p-button-primary p-0 mb-0"
                onClick={() => handleOnClickToMarkCashPaid()}
              >
                <p className="small-text">Mark paid via cash</p>
              </StyledButton>
            )}
            <StyledButton
              data-testid="accept-payment_OrderHeader"
              icon="pi pi-check-circle"
              className="p-button-text p-button-primary p-0 mb-0"
              onClick={() => acceptPayment(Steps.ORDER_OVERVIEW)}
            >
              <p className="small-text">Accept payment</p>
            </StyledButton>
          </>
        )}
        {isBariatricsNotReadyForTransfer && scope === UserRole.SUPER_ADMIN && (
          <StyledButton
            data-testid="ready-for-transfer_OrderHeader"
            icon="pi pi-check-circle"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => handleOnClickToMarkReadyForTransfer()}
          >
            <p className="small-text">Ready for Transfer</p>
          </StyledButton>
        )}
        {isStatusPaid && scope === UserRole.SUPER_ADMIN && (
          <StyledButton
            data-testid="ready-for-transfer_OrderHeader"
            icon="pi pi-check-circle"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => handleOnClickToMarkBlockedOrderForTransfer()}
          >
            <p className="small-text">Block Stripe Transfer</p>
          </StyledButton>
        )}
        <StyledButton
          data-testid="cancel-order_OrderHeader"
          icon="pi pi-file"
          className="p-button-text p-button-primary p-0 mb-0"
          onClick={handleOnClickToCancelOrder}
        >
          <p className="small-text">Cancel Order</p>
        </StyledButton>
        {showPartialPayment && (
          <StyledButton
            data-testid="money-transfer_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={handleOnClickPartialRefund}
          >
            <p className="small-text">Partial Refund</p>
          </StyledButton>
        )}
        {order.status !== orderStatusConstants.SENT_TO_PATIENT && (
          <StyledButton
            data-testid="update-card_OrderHeader"
            icon="pi pi-credit-card"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => {
              changeCardDetails(OrderUpdateSteps.ORDER_UPDATE);
            }}
          >
            <p className="small-text">Update Card</p>
          </StyledButton>
        )}
        <NoteToPdfDialog
          showDialog={showNoteToPdfDialog}
          setShowDialog={setShowNoteToPdfDialog}
          onSaveNote={handleSaveNoteForPdf}
          note={noteForPdfDialog}
          setNote={setNoteForPdfDialog}
          showHoursEstimated={!!isVersion3Pdf}
          hoursEstimated={hoursEstimated}
          setHoursEstimated={setHoursEstimated}
        />

        {(isGFE || isDefaultOrderType) &&
          (showDownloadWithNote || isVersion3Pdf ? (
            <StyledButton
              data-testid="add-note-to-pdf_OrderHeader"
              icon="pi pi-file"
              className="p-button-text p-button-primary p-0 mb-0"
              onClick={handleOnClickToAddNoteForPdf}
            >
              <p className="small-text">Download with Note</p>
            </StyledButton>
          ) : (
            <ClearOrderProDownloadButton data={dataForPdfDownload} />
          ))}
        {order.status === orderStatusConstants.SENT_TO_PATIENT ? (
          <StyledButton
            data-testid="redo-order_OrderHeader"
            icon="pi pi-undo"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={handleRedoOrder}
          >
            <p className="small-text">Redo Order</p>
          </StyledButton>
        ) : null}
      </div>
    </Container>
  );
}
