import { UserRole } from "constants/userRole";
import WithRoleProtection from "hoc/withRoleProtection";
import { PublicLayout } from "layout/components/PublicLayout";
import { CreateFeeSchedulePage } from "pages/CreateFeeSchedule";
import { CreateOrder } from "pages/CreateOrder";
import { ReviewOrder } from "pages/CreateOrder/ReviewOrder";
import { CustomizePage } from "pages/Customize";
import { ListCustoms } from "pages/Customize/ListCustoms";
import { FeeSchedulePage } from "pages/FeeSchedule";
import { HospitalPage } from "pages/Hospitals";
import { CreateHospitalPage, EditHospitalPage } from "pages/HospitalsPage";
import { NotFound } from "pages/NotFound";
import { OrderOverview } from "pages/OrderOverview";
import { Steps } from "pages/OrderOverview/utils/constants";
import { OrderUpdate } from "pages/OrderUpdate";
import { Steps as OrderUpdateSteps } from "pages/OrderUpdate/utils/constants";
import { OrderViewPage } from "pages/OrderView";
import { PatientsPage } from "pages/Patients";
import { EditPayerRulesPage } from "pages/PayerRulesPage";
import { CreatePayerPage } from "pages/PayerRulesPage/CreatePayerPage";
import { CreatePayerRulesPage } from "pages/PayerRulesPage/CreatePayerRulesPage";
import { EditCaseRateRulesPage } from "pages/PayerRulesPage/EditCaseRateRulesPage";
import { EditPayerPage } from "pages/PayerRulesPage/EditPayerPage";
import { CreateProvider } from "pages/Providers/CreateProvider";
import { EditProvider } from "pages/Providers/EditProvider";
import { Reports } from "pages/Reports";
import { CreateEditUser } from "pages/UserPages/CreateEditUser";
import { UsersPage } from "pages/Users";
import { VerificationPage } from "pages/VerificationPage";
import { ConfirmationSuccess } from "pages/VerificationPage/components/HospitalConfirmation/ConfirmationSuccess";
import { VerificationSteps } from "pages/VerificationPage/utils/constants";
import ViewHospitals from "pages/ViewHospitals/ViewHospitals";
import { RouteObject } from "react-router-dom";

import { AppLayout } from "../layout/components/AppLayout";
import { LoginPage, ValidateMFA } from "../pages/Login";
import { PayNow } from "../pages/OrderOverview/components/PayNow";
import { OrdersPage } from "../pages/Orders";
import { PayerRules } from "../pages/PayerRules";
import { CreateCaseRateRulesPage } from "../pages/PayerRulesPage/CreateCaseRateRulesPage";

export const routes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/ValidateMFA",
    element: <ValidateMFA />,
  },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/hospitals",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<HospitalPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "new",
            element: (
              <WithRoleProtection
                component={<CreateHospitalPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          { path: "view/:id", element: <ViewHospitals /> },
          {
            path: "edit/:id",
            element: (
              <WithRoleProtection
                component={<EditHospitalPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/patients",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<PatientsPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/providers",
        children: [
          { path: "new", element: <CreateProvider /> },
          {
            path: "edit/:providerId",
            element: <EditProvider />,
          },
        ],
      },
      {
        path: "/orders",
        children: [
          { index: true, element: <OrdersPage /> },
          { path: ":id", element: <OrderViewPage /> },
          { path: "new", element: <CreateOrder /> },
          { path: "review/:draftId", element: <ReviewOrder /> },
        ],
      },
      {
        path: "/reports",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<Reports />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/fee-schedule",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<FeeSchedulePage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "new",
            element: (
              <WithRoleProtection
                component={<CreateFeeSchedulePage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/users",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<UsersPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "new",
            element: (
              <WithRoleProtection
                component={<CreateEditUser />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <WithRoleProtection
                component={<CreateEditUser />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/customize",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<ListCustoms />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: ":id",
            element: (
              <WithRoleProtection
                component={<CustomizePage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "new/:id",
            element: (
              <WithRoleProtection
                component={<CustomizePage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
      {
        path: "/payer-rules",
        children: [
          {
            index: true,
            element: (
              <WithRoleProtection
                component={<PayerRules />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "mppr/new",
            element: (
              <WithRoleProtection
                component={<CreatePayerRulesPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "mppr/edit/:id",
            element: (
              <WithRoleProtection
                component={<EditPayerRulesPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "case-rate/new",
            element: (
              <WithRoleProtection
                component={<CreateCaseRateRulesPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "case-rate/edit/:id",
            element: (
              <WithRoleProtection
                component={<EditCaseRateRulesPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "payer/new",
            element: (
              <WithRoleProtection
                component={<CreatePayerPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
          {
            path: "payer/edit/:id",
            element: (
              <WithRoleProtection
                component={<EditPayerPage />}
                allowedRoles={[UserRole.SUPER_ADMIN]}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/order",
    element: <PublicLayout />,
    children: [
      {
        path: ":id",
        element: <OrderOverview variant={Steps.ORDER_OVERVIEW} />,
      },
      {
        path: "update/:id",
        element: <OrderUpdate variant={OrderUpdateSteps.ORDER_UPDATE} />,
      },
      {
        path: "pay/:id",
        element: <PayNow />,
      },
    ],
  },
  {
    path: "/receipt",
    element: <PublicLayout />,
    children: [
      {
        path: ":id",
        element: <VerificationPage variant={VerificationSteps.RECEIPT} />,
      },
    ],
  },
  {
    path: "/confirmation",
    element: <PublicLayout />,
    children: [
      {
        path: ":id",
        element: (
          <VerificationPage variant={VerificationSteps.HOSPITAL_CONFIRMATION} />
        ),
      },
      {
        path: "success",
        element: <ConfirmationSuccess />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
