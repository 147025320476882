import { Chip, ChipVariants } from "components/Chip";
import { validationConstants } from "constants/formValidation";
import { orderStatusConstants } from "constants/order";
import { UserRole } from "constants/userRole";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import { parsePrice, centsToDollars, dollarsToCents } from "helpers/price";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { handleChangeDateWithMask } from "pages/CreateOrder/utils/mask";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect, useState, useContext, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useUpdateOrderMutation } from "store/queries/order";
import { userSelector } from "store/slices/user";
import styled from "styled-components";
import { IAccount } from "types/Account/Account";
import { IPayment, IPaymentPlan, IInstallment } from "types/Payment";

import { validateNextInstallmentDate } from "./helpers/helper";

interface IPaymentDetailsEdit {
  paymentPlanMinDownPaymentPercent: number | null;
  paymentPlanDuration: number | null;
  paymentPlanRemainingAmount: number | null;
  paymentPlanMinDownPaymentType?: string;
  paymentPlanMinDownPaymentFlat?: number | null;
  installments?: IInstallment[];
  paymentPlanEnabled: boolean;
  nextInstallmentDate: string;
}

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
  flex-grow: 1;
`;

function PaymentDetails({
  payments,
  paymentPlan,
  orderId,
  onUpdateOrder,
  editable,
  showPaymentPlan,
  orderStatus,
  account,
  showPaymentPlanEnableButton,
}: {
  payments?: IPayment[];
  paymentPlan: IPaymentPlan;
  orderId: string;
  onUpdateOrder: () => void;
  editable: boolean;
  showPaymentPlan: boolean;
  showPaymentPlanEnableButton: boolean;
  orderStatus: string;
  account: IAccount;
}) {
  const [editing, setEditing] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const {
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IPaymentDetailsEdit>();
  const [updateOrder] = useUpdateOrderMutation();
  const { current: toastElement } = useContext(ToastContext);
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();
  const { scope } = useSelector(userSelector);

  const resetForm = () => {
    setValue(
      "paymentPlanMinDownPaymentPercent",
      paymentPlan?.paymentPlanMinDownPaymentPercent
    );
    setValue(
      "paymentPlanRemainingAmount",
      paymentPlan?.remainingAmountInCents
        ? centsToDollars(paymentPlan?.remainingAmountInCents)
        : null
    );
    setValue(
      "paymentPlanMinDownPaymentFlat",
      paymentPlan?.paymentPlanMinDownPaymentFlat
        ? centsToDollars(paymentPlan?.paymentPlanMinDownPaymentFlat)
        : null
    );
    setValue(
      "paymentPlanMinDownPaymentType",
      paymentPlan?.paymentPlanMinDownPaymentFlat ? "Flat amount" : "Percentage"
    );
    setValue("paymentPlanEnabled", !!paymentPlan);

    // Set Value for paymentPlanDuration and nextInstallmentDate
    if (!paymentPlan?.installments?.length) {
      setValue("nextInstallmentDate", "");
      setValue("paymentPlanDuration", paymentPlan?.paymentPlanDuration);
      return;
    }
    // Sort installments Array by dateDue to make sure we get the first installment date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset today's time to midnight
    const sortedInstallments = [...paymentPlan.installments]
      .filter((installment) => {
        const installmentDate = new Date(installment.dateDue);
        installmentDate.setHours(0, 0, 0, 0); // Reset installment's time to midnight
        return installmentDate >= today; // Compare only the date parts
      })
      .sort(
        (a, b) => new Date(a.dateDue).getTime() - new Date(b.dateDue).getTime()
      ); // Sort by dateDue

    // remaining installments with null paymentId
    const nullPaymentIdCount = sortedInstallments.reduce(
      (count, installment) => {
        return !installment.paymentId ? count + 1 : count;
      },
      0
    );
    // first installment with a null paymentId
    const firstNullPaymentIdInstallment = sortedInstallments.find(
      (installment) => !installment.paymentId
    );
    const nextInstallmentDate = firstNullPaymentIdInstallment?.dateDue
      ? parseDate(firstNullPaymentIdInstallment.dateDue)
      : "-";

    setValue("paymentPlanDuration", nullPaymentIdCount);
    setValue("nextInstallmentDate", nextInstallmentDate);
  };

  useEffect(() => {
    if (!isFirstRender) {
      return;
    }
    resetForm();
    setIsFirstRender(false);
  }, [paymentPlan, setValue, account]);

  const minDownPaymentType = watch("paymentPlanMinDownPaymentType");
  const minDownPaymenFlat = watch("paymentPlanMinDownPaymentFlat");
  const minDownPaymentPercent = watch("paymentPlanMinDownPaymentPercent");
  const nextInstallmentDateField = watch("nextInstallmentDate");
  const remainingAmountField = watch("paymentPlanRemainingAmount");
  const remainingInstallmentsField = watch("paymentPlanDuration");
  const minDownPaymentEnabled = useMemo(() => {
    if (orderStatus !== orderStatusConstants.SENT_TO_PATIENT || !editing) {
      return false;
    }
    return true;
  }, [orderStatus, editing]);

  const showPercentageField = useMemo(() => {
    if (!minDownPaymentType) {
      return false;
    }
    if (minDownPaymentType === "Percentage") {
      return true;
    }
    return false;
  }, [minDownPaymentType]);

  const showFlatAmountField = useMemo(() => {
    if (!minDownPaymentType) {
      return false;
    }
    if (minDownPaymentType === "Flat amount") {
      return true;
    }
    return false;
  }, [minDownPaymentType]);

  const showNextInstallmentDate = useMemo(
    () =>
      orderStatus !== orderStatusConstants.PAID &&
      !!paymentPlan?.installments?.length,
    [orderStatus, paymentPlan]
  );

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (minDownPaymentType === "Percentage") {
      setValue("paymentPlanMinDownPaymentFlat", null);
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlan?.paymentPlanMinDownPaymentPercent || null
      );
    } else if (minDownPaymentType === "Flat amount") {
      setValue("paymentPlanMinDownPaymentPercent", null);
      setValue(
        "paymentPlanMinDownPaymentFlat",
        paymentPlan?.paymentPlanMinDownPaymentFlat
          ? centsToDollars(paymentPlan.paymentPlanMinDownPaymentFlat)
          : null
      );
    }
  }, [minDownPaymentType]);

  useEffect(() => {
    const validateFields = async () => {
      let isValidFlatAmount = false;
      let isValidNextInstallmentDate = false;
      let isValidRemainingAmount = false;
      const isValidRemainingInstallments =
        remainingInstallmentsField !== null &&
        remainingInstallmentsField !== undefined;
      const isPercentageType = minDownPaymentType === "Percentage";
      const isFlatValid = await trigger("paymentPlanMinDownPaymentFlat");
      const isPercentageValid = await trigger(
        "paymentPlanMinDownPaymentPercent"
      );
      if (isPercentageType) {
        isValidFlatAmount = !!isPercentageValid;
      } else {
        isValidFlatAmount = !!isFlatValid;
      }
      if (!showNextInstallmentDate) {
        isValidNextInstallmentDate = true;
      } else {
        const validNextInstallmentDate = validateNextInstallmentDate(
          nextInstallmentDateField,
          "future"
        );
        isValidNextInstallmentDate =
          typeof validNextInstallmentDate !== "string";
      }
      if (orderStatus === orderStatusConstants.SENT_TO_PATIENT) {
        isValidRemainingAmount = true;
      } else {
        isValidRemainingAmount =
          remainingAmountField !== null && remainingAmountField !== undefined;
      }
      setSubmitButtonEnabled(
        isValidFlatAmount &&
          isValidNextInstallmentDate &&
          isValidRemainingAmount &&
          isValidRemainingInstallments
      );
    };
    validateFields();
  }, [
    minDownPaymenFlat,
    minDownPaymentPercent,
    trigger,
    minDownPaymentType,
    nextInstallmentDateField,
    remainingInstallmentsField,
    remainingAmountField,
  ]);

  const paidPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Paid"
  );
  const refundedPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Refunded"
  );

  const mapPaymentDetailsUpdateRequest = (values: IPaymentDetailsEdit) => {
    return {
      paymentPlan: [
        {
          remainingAmountInCents:
            values.paymentPlanRemainingAmount !== null &&
            values.paymentPlanRemainingAmount !== undefined
              ? dollarsToCents(values.paymentPlanRemainingAmount)
              : null,
          paymentPlanDuration: values.paymentPlanDuration,
          paymentPlanMinDownPaymentPercent:
            values.paymentPlanMinDownPaymentPercent,
          active: values.paymentPlanEnabled,
          installments: paymentPlan?.installments,
          paymentPlanMinDownPaymentFlat: values.paymentPlanMinDownPaymentFlat
            ? dollarsToCents(values.paymentPlanMinDownPaymentFlat)
            : null,
          nextInstallmentDate: values.nextInstallmentDate || null,
        },
      ],
    };
  };

  const submit = () => {
    setSaveButtonLoading(true);
    const values = mapPaymentDetailsUpdateRequest(getValues());
    updateOrder({ id: orderId, ...values })
      .unwrap()
      .then(() => {
        setSaveButtonLoading(false);
        onUpdateOrder();
        toastElement?.show({
          summary: "Success!",
          severity: "success",
          detail: "Your order has been updated.",
        });
        setEditing(false);
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };
  const actionFooter = () => {
    return (
      <div className="w-full">
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={submit}
          loading={saveButtonLoading}
          disabled={!submitButtonEnabled}
        />
        <Button
          label="Cancel"
          onClick={() => {
            resetForm();
            setEditing(false);
          }}
          icon="pi pi-times"
          style={{ marginLeft: "0.5em" }}
        />
      </div>
    );
  };
  const actionHeader = () => {
    return (
      <Button
        label="Edit"
        icon="pi pi-check"
        onClick={() => setEditing(true)}
      />
    );
  };

  const remainingAmountDisabled = useMemo(() => {
    if (orderStatus === orderStatusConstants.SENT_TO_PATIENT) {
      return true;
    }
    return !editing;
  }, [orderStatus, editing]);
  return (
    <StyledCard
      title="Payment details"
      header={
        refundedPayment && (
          <div className="flex justify-content-end pt-2 pr-3">
            <Chip variant={ChipVariants.WARNING}>Refunded</Chip>
          </div>
        )
      }
    >
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentDate">Payment Date</Label>

          <InputText
            disabled
            id="paymentDate"
            aria-labelledby="paymentDate"
            value={
              paidPayment?.createdAt ? parseDate(paidPayment.createdAt) : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentAmount">Payment Amount</Label>

          <InputText
            disabled
            id="paymentAmount"
            aria-labelledby="paymentAmount"
            value={
              paidPayment?.amountInCents
                ? parsePrice(centsToDollars(paidPayment.amountInCents))
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="depositDate">Deposit Date</Label>

          <InputText
            disabled
            id="depositDate"
            aria-labelledby="depositDate"
            value={
              paidPayment?.stripePayout?.payoutArrivalDate
                ? parseDate(paidPayment.stripePayout.payoutArrivalDate)
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentMethod">Payment Method</Label>

          <InputText
            disabled
            id="paymentMethod"
            value={paidPayment?.type || "-"}
            aria-labelledby="paymentMethod"
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentStatus">Status</Label>

          <InputText
            disabled
            id="paymentStatus"
            value={paidPayment?.status || "-"}
            aria-labelledby="paymentStatus"
          />
        </div>
      </div>
      {showPaymentPlan && (
        <div className="flex flex-column gap-2">
          <div className="flex justify-content-between w-full">
            <p className="font-bold text-lg mb-3">Payment Plan</p>
            <div style={{ height: "46px" }}>
              {!editing &&
                editable &&
                orderStatus !== orderStatusConstants.NO_BALANCE_DUE &&
                actionHeader()}
            </div>
          </div>
          <div className="formgrid grid w-100">
            <div className="field flex flex-column col-12 md:col-3">
              <Label
                htmlFor="paymentPlanMinDownPaymentType"
                data-testid="paymentPlanMinDownPaymentType"
              >
                Minimum down payment type
              </Label>
              <Controller
                name="paymentPlanMinDownPaymentType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    id="paymentPlanMinDownPaymentType"
                    data-testid="minDownPaymentType_PaymentPlan"
                    value={value}
                    disabled={!minDownPaymentEnabled}
                    onChange={onChange}
                    options={["Percentage", "Flat amount"]}
                    placeholder="Select minimum down payment type"
                    className="w-full"
                  />
                )}
              />
            </div>
            {showPercentageField && (
              <div className="field flex flex-column col-12 md:col-4">
                <Label htmlFor="paymentPlanMinDownPaymentPercent">
                  Minimum Down Payment
                </Label>
                <Controller
                  data-testid="paymentPlanMinDownPaymentPercent"
                  name="paymentPlanMinDownPaymentPercent"
                  control={control}
                  rules={{
                    required:
                      validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_PERCENT,
                  }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <>
                      <InputNumber
                        disabled={!minDownPaymentEnabled}
                        value={value}
                        min={5}
                        onValueChange={onChange}
                        onBlur={() =>
                          handleOnBlurField({
                            onBlur,
                            field: "paymentPlanMinDownPaymentPercent",
                            trigger,
                          })
                        }
                        suffix="%"
                        inputId="paymentPlanMinDownPaymentPercent"
                        data-testid="paymentPlanMinDownPaymentPercent"
                      />
                      {getFormErrorMessage(
                        "paymentPlanMinDownPaymentPercent",
                        errors
                      )}
                    </>
                  )}
                />
              </div>
            )}
            {showFlatAmountField && (
              <div className="field flex flex-column col-12 md:col-3">
                <Label
                  htmlFor="paymentPlanMinDownPaymentFlat"
                  data-testid="paymentPlanMinDownPaymentFlat"
                >
                  Minimum first payment flat
                </Label>

                <Controller
                  name="paymentPlanMinDownPaymentFlat"
                  control={control}
                  rules={{
                    required:
                      validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_FLAT,
                  }}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <>
                      <InputNumber
                        value={value}
                        currency="USD"
                        locale="en-US"
                        mode="currency"
                        disabled={!minDownPaymentEnabled}
                        onBlur={() =>
                          handleOnBlurField({
                            onBlur,
                            field: "paymentPlanMinDownPaymentFlat",
                            trigger,
                          })
                        }
                        inputId="paymentPlanMinDownPaymentFlat"
                        data-testid="minDownPaymentFlat_PaymentPlan"
                        onValueChange={onChange}
                        min={10}
                        aria-autocomplete="none"
                      />
                      {getFormErrorMessage(
                        "paymentPlanMinDownPaymentFlat",
                        errors
                      )}
                    </>
                  )}
                />
              </div>
            )}
            {editable && (
              <div className="field flex flex-column col-12 md:col-4">
                <Label htmlFor="paymentPlanDuration">
                  Remaining Installments
                </Label>
                <Controller
                  data-testid="paymentPlanDuration"
                  name="paymentPlanDuration"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <InputNumber
                      disabled={!editing}
                      id="paymentPlanDuration"
                      aria-labelledby="paymentPlanDuration"
                      value={value}
                      onValueChange={onChange}
                      min={0}
                    />
                  )}
                />
              </div>
            )}
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="paymentPlanRemainingAmount">
                Remaining Amount
              </Label>
              <Controller
                data-testid="paymentPlanRemainingAmount"
                name="paymentPlanRemainingAmount"
                control={control}
                render={({ field: { value, onChange } }) =>
                  remainingAmountDisabled && !value ? (
                    <InputText value="-" disabled />
                  ) : (
                    <InputNumber
                      disabled={remainingAmountDisabled}
                      value={value}
                      currency="USD"
                      locale="en-US"
                      mode="currency"
                      inputId="paymentPlanRemainingAmount"
                      data-testid="paymentPlanRemainingAmount"
                      onValueChange={onChange}
                      min={0}
                    />
                  )
                }
              />
            </div>
            {showNextInstallmentDate && (
              <div className="field flex flex-column col-12 md:col-4">
                <Label htmlFor="nextInstallmentDate">
                  Next Installment Date
                </Label>
                <Controller
                  data-testid="nextInstallmentDate"
                  name="nextInstallmentDate"
                  control={control}
                  rules={{
                    validate: (date) =>
                      validateNextInstallmentDate(date, "future"),
                    required:
                      validationConstants.PAYMENT_PLAN.NEXT_INSTALLMENT_DATE,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <InputText
                        disabled={!editing}
                        id="nextInstallmentDate"
                        value={value || ""}
                        placeholder="99/99/9999"
                        onBlur={() =>
                          handleOnBlurField({
                            onBlur,
                            field: "nextInstallmentDate",
                            trigger,
                          })
                        }
                        onChange={(e) => handleChangeDateWithMask(e, onChange)}
                      />

                      {getFormErrorMessage("nextInstallmentDate", errors)}
                    </>
                  )}
                />
              </div>
            )}
            {showPaymentPlanEnableButton && (
              <div className="field flex flex-column col-12 md:col-3">
                <Label
                  htmlFor="paymentPlanEnabled"
                  data-testid="paymentPlanEnabled"
                >
                  Payment Plan Enabled
                </Label>
                <Controller
                  name="paymentPlanEnabled"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ToggleButton
                      checked={value}
                      onChange={onChange}
                      className="w-8rem"
                      disabled={!editing}
                    />
                  )}
                />
              </div>
            )}
          </div>
          {editing && actionFooter()}
        </div>
      )}
    </StyledCard>
  );
}

export default PaymentDetails;
